<template>
  <div class="product-detail-page">
    <Breadcrumbs />
    <section class="product product-section" v-if="product">
      <div class="container">
        <div class="product-header">
          <span class="product--name d-block">{{ product.name }}</span>
          <div v-if="product.brand != null" class="product-logo">
            <b-link :to="`/` + product.brand.urlKey">
              <img :src="product.brand.logo" :alt="product.brand.title" />
            </b-link>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-lg-7 col-md-12">
            <ProductGallery
              v-if="product.media_gallery.length > 0"
              @changeIsInWishList="UpdateIsInWishList"
              :images="
                product.media_gallery.length > 0
                  ? product.media_gallery
                  : [product.image]
              "
              :isInWishList="
                $store.getters['user/isProductInWishlist'](product.sku)
              "
            />
          </div>
          <div class="col-12 col-lg-5 col-md-12">
            <div class="product-info">
              <ProductParameters
                @changeIsInWishList="UpdateIsInWishList"
                v-if="product.__typename != 'BundleProduct'"
                :isInWishList="
                  $store.getters['user/isProductInWishlist'](product.sku)
                "
              />
              <ProductParametersBundle
                v-if="product.__typename == 'BundleProduct'"
                @changeIsInWishList="UpdateIsInWishList"
                :isInWishList="
                  $store.getters['user/isProductInWishlist'](product.sku)
                "
              />
              <!-- <BundelTips class="d-none d-md-block" /> -->
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12 col">
            <DescriptionTab />
            <div v-if="related.length > 0" class="product-gallery-block">
              <div class="section-title with-border">
                <h2 class="semiBold-18">{{ $t("offers") }}</h2>
              </div>
              <MoreProductsCarousel :products="related" />
            </div>
            <div
              class="product-form"
              v-if="productForm != null"
              id="product-form"
            >
              <dynamic-forms :product_sku="product.sku" type="product" />
            </div>
            <div class="product-review" id="product-review">
              <ProductReview></ProductReview>
            </div>
          </div>
        </div>

        <!-- <div class="row d-md-none">
          <DescriptionTab class="col-12 col-md-6" />
          <BundlesCarousel class="col-12 p-0 pt-15" />
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import Breadcrumbs from "@/base/core/components/breadcrumb/Breadcrumbs.vue";
import ProductGallery from "@/base/core/components/product-detail/ProductGallery.vue";
import ProductParameters from "@/esf_antarctica_feestenkleding/core/components/product-detail/ProductParameters.vue";
import ProductParametersBundle from "@/base/core/components/product-detail/ProductParametersBundle.vue";
import DescriptionTab from "@/base/core/components/product-detail/DescriptionTab.vue";
import MoreProductsCarousel from "@/base/core/components/product-detail/MoreProductsCarousel.vue";
import { Logger } from "@storefront/core/lib/logger";
import DynamicForms from "@/base/core/components/common/DynamicForms.vue";
import { isServer } from "@storefront/core/helpers";
import ProductReview from "@/esf_antarctica_feestenkleding/core/components/product-detail/ProductReview.vue";
import config from "@config";
export default {
  name: "ProductPage",
  components: {
    Breadcrumbs,
    ProductGallery,
    ProductParameters,
    ProductParametersBundle,
    DescriptionTab,
    MoreProductsCarousel,
    DynamicForms,
    ProductReview,
  },

  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      related: "product/getRelated",
    }),

    showDismissibleAlert() {
      return false;
    },
    productForm() {
      return this.$store.getters["forms/getProductForm"];
    },
    getGTMItems() {
      let items = {
        item_name: this.product.name,
        item_id: this.product.sku,
        quantity: this.quantity,
        price: this.finalPrice,
      };
      if (this.finalPrice != this.regularPrice) {
        items["discount"] = this.regularPrice - this.finalPrice;
      }
      return items;
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum_price.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum_price.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    regularPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.regular_price.value !=
          this.product.price_range.maximum_price.regular_price.value
        ) {
          return (
            this.product.price_range.minimum_price.regular_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.regular_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.regular_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.regular_price.value !=
        this.product.price_range.maximum_price.regular_price.value
      ) {
        return (
          this.product.price_range.minimum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.regular_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.regular_price.value.toFixed(
        2
      );
    },
  },
  mounted() {
    if (!isServer) {
      if (this.$gtm.enabled()) {
        window.dataLayer.push({
          event: "eec.detail",
          ecommerce: {
            detail: {
              currency: "EUR",
              value: this.finalPrice,
              products: [
                {
                  name: this.product.name,
                  id: this.product.sku,
                  price: this.regularPrice,
                  discount: (this.regularPrice - this.finalPrice).toFixed(2),
                },
              ],
            },
          },
        });
      }
    }
  },
  watch: {
    product() {
      if (!isServer) {
        if (this.$gtm.enabled()) {
          window.dataLayer.push({
            event: "eec.detail",
            ecommerce: {
              detail: {
                currency: "EUR",
                value: this.finalPrice,
                products: [
                  {
                    name: this.product.name,
                    id: this.product.sku,
                    price: this.regularPrice,
                    discount: (this.regularPrice - this.finalPrice).toFixed(2),
                  },
                ],
              },
            },
          });
        }
      }
    },
  },
  methods: {
    UpdateIsInWishList() {
      if (this.$store.getters["user/getIsLoggedIn"] == true) {
        if (
          this.$store.getters["user/isProductInWishlist"](this.product.sku) ==
          true
        ) {
          this.$store.dispatch("user/setProductWishlistStatus", {
            sku: this.product.sku,
            parentSku: null,
          });
        } else {
          if (this.product.__typename == "SimpleProduct") {
            this.$store.dispatch("user/setProductWishlistStatus", {
              sku: this.product.sku,
              parentSku: null,
            });
          } else {
            const retval = this.$store.getters["product/getCurrentChildSku"];
            if (retval == null) {
              const msg = {
                type: "danger",
                title: this.$t("wishlist error"),
                text: this.$t("wishlist_select_options"),
              };
              this.$store.dispatch("messages/sendMessage", { message: msg });
              //this.$router.push("/" + this.product.url_key);
            } else {
              Logger.debug(
                "ChildSku",
                "Product.vue",
                this.$store.getters["product/getCurrentChildSku"]
              )();
              Logger.debug("Sku", "Product.vue", this.product.sku)();
              this.$store.dispatch("user/setProductWishlistStatus", {
                sku: this.$store.getters["product/getCurrentChildSku"],
                parentSku: this.product.sku,
              });
            }
          }
        }
      } else {
        const msg = {
          type: "danger",
          title: this.$t("wishlist error"),
          text: this.$t("wishlist_logged_in"),
        };
        this.$store.dispatch("messages/sendMessage", { message: msg });
      }
    },
  },
  data() {
    return {};
  },
  metaInfo() {
    return {
      meta: [
        { name: "title", content: this.product ? this.product.meta_title : "" },
        {
          name: "keywords",
          content: this.product ? this.product.meta_keyword : "",
        },
        {
          name: "description",
          content: this.product ? this.product.meta_description : "",
        },
        { property: "og:type", content: "product" },
        {
          property: "og:title",
          content: this.product ? this.product.meta_title : "",
        },
        {
          property: "og:image",
          content: this.product ? this.product.image.url : "",
        },
        {
          property: "og:description",
          content: this.product ? this.product.meta_description : "",
        },
        {
          property: "product:price:amount",
          content: this.product
            ? this.product.price_range.minimum_price.final_price.value
            : "",
        },
        {
          property: "product:price:currency",
          content: "EUR",
        },
      ],
      //test 3
      // if no subcomponents specify a metaInfo.title, this title will be used
      title: this.product ? this.product.name : "",
      link: [
        {
          rel: "canonical",
          href:
            config.app_url +
            "/" +
            (this.product ? this.product.canonical_url : this.$route.fullPath),
        },
      ],
    };
  },
};
</script>

<style lang="scss">
.product-section {
  .product-header {
    display: flex;
    justify-content: space-between;
    margin: 10px 0 20px 0;
    .product-logo {
      width: 200px;
    }
  }
  .product--name {
    width: calc(100% - 200px);
    padding-right: 50px;
    font-size: 20px;
    line-height: 30px;
    font-weight: normal;
    display: block;
  }
  @media screen and (max-width: 768px) {
    .product--name {
      width: 100%;
      padding-right: 0;
      font-size: 18px;
    }
  }
  @media screen and (max-width: 650px) {
    .product--name {
      font-size: 16px;
      line-height: 24px;
    }
  }
}

// .product-gallery-block {
//   h2 {
//     margin-bottom: 5px;
//     font-weight: 700;
//     color: #0c1f47;
//     font-size: 20px;
//     letter-spacing: 0.01em;
//   }
// }
</style>
