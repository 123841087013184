<template>
  <div>
    <NotifyModal
      :show="addToNotifyModal"
      :product-sku="product.sku"
      @close="closeNotifyModal"
    />

    <b-modal
      :id="`modal-${product.sku}`"
      v-model="addToCartModal"
      size="lg"
      content-class="product-popup"
      centered
      :hide-footer="true"
    >
      <div class="product-popup-body">
        <img :src="product.thumbnail.url" />

        <div class="info-popup">
          <h2>{{ product.name }}</h2>
          <div class="info-popup-wrap">
            <div class="info-wrap">
              <p class="my-price">
                {{ formatCurrency(priceRange.maximum_price.final_price.value) }}
              </p>
              <p><label>{{ $t("number") }}</label> : {{ quantity }}</p>
              <template
                v-if="
                  product.__typename == 'ConfigurableProduct' &&
                  currentChildElement
                "
              >
                <p v-if="currentChildElementInfo['size']">
                  <label>{{ $t("size") }}</label> : {{ currentChildElementInfo["size"].label }}
                </p>
                <p v-if="currentChildElementInfo['color']">
                  <label>{{ $t("color") }}</label> :
                  <span
                    class="m-color-box"
                    :title="currentChildElementInfo['color'].label"
                    :style="{
                      backgroundColor: getColorCode(
                        currentChildElementInfo['color'].value_index
                      ),
                    }"
                  ></span>
                </p>
              </template>
            </div>
            <div class="action-btn">
              <b-link @click="clickOk" class="c-btn c-btn-primary">{{
                $t("continue_buying")
              }}</b-link>
              <b-link @click="clickCancel" class="btn-link">
                <b-icon icon="chevron-left"></b-icon>
                {{ $t("continue_shopping") }}
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </b-modal>

    <!-- compare model -->
    <b-modal
      :id="`modal-compare`"
      v-model="addToCompareModal"
      size="lg"
      content-class="product-popup product-compare-popup"
      centered
      :hide-footer="true"
    >
      <div class="product-popup-body">
        <img :src="product.thumbnail.url" />

        <div class="info-popup">
          <h2>{{ product.name }}</h2>
          <div class="info-popup-wrap">
            <div class="info-wrap">
              <p class="my-price">
                <span
                  v-if="
                    product.price_range.minimum_price.final_price.value ==
                    product.price_range.maximum_price.final_price.value
                  "
                  class="d-block product-card--price"
                >
                  {{
                    formatCurrency(
                      product.price_range.minimum_price.final_price.value
                    )
                  }}
                </span>
                <span
                  v-if="
                    product.price_range.minimum_price.final_price.value !=
                    product.price_range.maximum_price.final_price.value
                  "
                  class="d-block product-card--price"
                >
                  {{
                    formatCurrency(
                      product.price_range.minimum_price.final_price.value
                    )
                  }}
                  -
                  {{
                    formatCurrency(
                      product.price_range.maximum_price.final_price.value
                    )
                  }}
                </span>
              </p>
              <p>{{ $t("number") }} : {{ quantity }}</p>
            </div>
            <div class="action-btn">
              <b-link @click="clickOkCompare" class="c-btn c-btn-primary">{{
                $t("compare")
              }}</b-link>
              <b-link @click="clickCancelCompare" class="btn-link">
                <b-icon icon="chevron-left"></b-icon>
                {{ $t("continue_shopping") }}
              </b-link>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- compare model -->
    <ClientOnly>
      <div
        class="size-selection"
        v-if="product.__typename == 'ConfigurableProduct' && options.length > 0"
      >
        <div v-for="parameter of options" :key="parameter.id">
          <div
            v-if="parameter.attribute_code === 'color'"
            class="color-selection-radio"
          >
            <label class="title">Kleur</label>
            <ul class="available-colors">
              <li
                v-for="opt of parameter.values"
                :key="opt.value_index"
                class="radio-color"
              >
                <input
                  type="radio"
                  :id="`${parameter.attribute_code}-${opt.value_index}`"
                  :name="`${parameter.attribute_code}-select`"
                  :value="opt.value_index"
                  v-on:click="processOption(parameter.index, opt.value_index)"
                />
                <label
                  class="custom-radio-input"
                  :title="opt.label"
                  :for="`${parameter.attribute_code}-${opt.value_index}`"
                  :style="{
                    borderColor: getColorCode(opt.value_index),
                  }"
                >
                  <span
                    class="color-box"
                    :style="{
                      backgroundColor: getColorCode(opt.value_index),
                    }"
                  ></span>
                  <!-- <span class="custom-radio-input font-weight-normal">
                  {{ opt.label }}
                </span> -->
                </label>
              </li>
            </ul>
          </div>
          <div
            class="size-dropdown"
            v-else-if="parameter.attribute_code == 'size'"
          >
            <label>{{ parameter.label }}</label>
            <div class="select-wrapper">
              <i class="fas fa-caret-down"></i>
              <el-select
                v-on:change="processOption(parameter.index, priceSize)"
                v-model="priceSize"
                :placeholder="$t('select_measure')"
                no-data-text="no-data"
                :disabled="parameter.values.length == 0"
              >
                <template slot="prefix">{{ priceSize }}</template>
                <el-option
                  v-for="opt of parameter.values"
                  :key="opt.value_index"
                  :label="opt.label"
                  :value="opt.value_index"
                >
                  <span>{{ opt.label }}</span>
                  <!-- <span>{{ formatCurrency(opt.price) }}</span> -->
                </el-option>
              </el-select>
            </div>
          </div>
          <div v-else class="size-selection-radio">
            <ul class="size-selection--available-sizes">
              <li v-for="opt of parameter.values" :key="opt.value_index">
                <label class="custom-radio-input">
                  <input
                    type="radio"
                    :id="`${parameter.attribute_code}-${opt.value_index}`"
                    :name="`${parameter.attribute_code}-select`"
                    :value="opt.value_index"
                    v-on:click="processOption(parameter.index, opt.value_index)"
                  />
                  <span class="custom-radio-input--btn font-weight-normal">
                    {{ opt.label }}
                  </span>
                </label>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </ClientOnly>
    <div class="price-part">
      <div v-if="isInStock">
        <div class="quantity-and-btn">
          <label>{{ $t("number") }}</label>
          <div class="quantity-input">
            <b-link class="step-down-btn" @click="quantityDown"></b-link>
            <input
              type="number"
              min="1"
              :value="quantity"
              name="quantity"
              ref="inputQuantity"
              disabled
            />
            <b-link href="#" class="step-up-btn" @click="quantityUp"></b-link>
          </div>
        </div>
      </div>
      <div
        v-if="
          priceRange.minimum_price.final_price.value !=
          priceRange.maximum_price.final_price.value
        "
        class="top-wrap aaa"
      >
        <div
          v-if="
            priceRange.maximum_price.regular_price.value >
            priceRange.minimum_price.final_price.value
          "
          class="price-selection"
        >
          <span class="normal-price">
            <!-- {{ $t("advice_price") }}:  -->

            <span>
              {{ formatCurrency(priceRange.maximum_price.regular_price.value) }}
            </span>
          </span>
        </div>
        <div class="price-selection">
          <span class="currentPrice">
            {{ formatCurrency(priceRange.minimum_price.final_price.value) }}
            - {{ formatCurrency(priceRange.maximum_price.final_price.value) }}
          </span>
          <span
            class="discount-comment"
            v-if="priceRange.minimum_price.discount.percent_off > 0"
          >
            {{ $t("discount") }}
            {{ priceRange.minimum_price.discount.percent_off.toFixed(0) }}%
          </span>
          <span
            class="discount-comment2"
            v-if="
              priceRange.maximum_price.discount.percent_off >
              priceRange.minimum_price.discount.percent_off
            "
          >
            {{ $t("to_discount") }}
            {{ priceRange.maximum_price.discount.percent_off.toFixed(0) }}%
          </span>
        </div>
      </div>
      <div
        v-if="
          priceRange.minimum_price.final_price.value ==
          priceRange.maximum_price.final_price.value
        "
        class="top-wrap"
      >
        <div
          v-if="
            priceRange.minimum_price.regular_price.value >
            priceRange.minimum_price.final_price.value
          "
          class="price-selection"
        >
          <span class="normal-price">
            <!-- {{ $t("advice_price") }}: -->
            <span>
              {{ formatCurrency(priceRange.minimum_price.regular_price.value) }}
            </span>
          </span>
        </div>
        <div class="price-selection">
          <span class="currentPrice">
            {{ formatCurrency(priceRange.minimum_price.final_price.value) }}
          </span>
          <span
            class="discount-comment"
            v-if="priceRange.minimum_price.discount.percent_off > 0"
          >
            {{ $t("discount") }}
            {{ priceRange.minimum_price.discount.percent_off.toFixed(0) }}%
          </span>
        </div>
      </div>
    </div>

    <div class="out-stock-box" :class="stockStatusClass">
      <h3>{{ $t(isInStock ? "In Stock" : "Out of Stock") }}</h3>
    </div>

    <button
      v-if="isInStock"
      v-on:click="addToCart"
      v-bind:disabled="btnDisable"
      :style="buttonClass"
      class="product-parameter-submit-btn"
    >
      <i class="fas fa-shopping-bag"></i>
      {{ $t("add_to_cart") }}
    </button>
    <button
      v-else
      v-on:click="addToNotify"
      class="product-parameter-submit-btn"
    >
      <i class="fas fa-envelope"></i>
      {{ $t("notify_btn") }}
    </button>

    <div class="comon-wrap">
      <div class="whish-list-add" @click.stop="toggleIsInWishList">
        <div
          class="heart-icon"
          v-if="$store.getters['user/isProductInWishlist'](product.sku)"
          :class="{ selected: isInWishList }"
        >
          <b-icon icon="heart-fill"></b-icon>
        </div>
        <div class="heart-icon" v-else>
          <b-icon icon="heart"></b-icon>
        </div>
        <span>{{ $t("add_to_wish_list") }}</span>
      </div>

      <div class="compare-list" @click="onCompareClick">
        <div class="compare-icon" v-if="!isProductInCompare">
          <!-- v-if="getIsProductInCompareList(product.sku) ? '' : 'added'" -->
          <b-icon icon="square"></b-icon>
        </div>
        <div class="compare-icon added" v-else>
          <b-icon icon="check-square-fill"></b-icon>
        </div>

        <span>{{ $t("show_compararison") }}</span>
      </div>
    </div>

    <span
      class="d-block delivery-info mb-20"
      v-if="product.DeliveryTime != null"
      :style="'color: ' + product.DeliveryTime.color + '!important'"
      >{{ $t("delivery_time") }}: {{ product.DeliveryTime.long }}</span
    >

    <div
      v-if="availability.length > 0"
      class="d-block mb-2"
      style="font-weight: 500"
    >
      {{ $t("store_availability") }}:
    </div>

    <div
      class="d-flex flex-column flex-md-row justify-content-between more-info"
      v-if="availability.length > 0"
    >
      <div class="available-stores">
        <div v-for="(astore, index) in availability" :key="`astores-${index}`">
          {{ astore.store.name }}
        </div>
      </div>
      <div class="stock">
        <div v-for="(astore, index) in availability" :key="`sstores-${index}`">
          <span v-if="astore.has_stock == true" class="d-block text-info">
            {{ $t("in_stock") }}
          </span>
          <span v-if="astore.has_stock != true" class="d-block">
            {{ $t("not_in_stock") }}
          </span>
        </div>
      </div>
    </div>
    <div class="d-block sku">SKU: {{ product.sku }}</div>

    <div
      class="product-extra-info"
      v-html="product.short_description.html"
    ></div>
    <ClientOnly>
      <div class="product-share">
        <a :href="`mailto:?body=${getCurrentUrl}`">
          <i class="fas fa-share-alt"></i>
          <span>{{ $t("email_to_friend") }}</span>
        </a>
      </div>
    </ClientOnly>
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { mapGetters } from "vuex";
import { Logger } from "@storefront/core/lib/logger";
import ClientOnly from "vue-client-only";
import { isServer } from "@storefront/core/helpers";
import NotifyModal from "@/base/core/components/common/NotifyModal.vue";

export default {
  name: "ProductParameters",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    ClientOnly,
    NotifyModal,
  },
  props: {
    parameters: { type: Object },
    isInWishList: { type: Boolean },
  },
  mounted() {
    // console.log("INN");
  },
  computed: {
    ...mapGetters({
      product: "product/getCurrentProduct",
      productConfig: "product/getCurrentConfig",
      options: "product/getCurrentProductOptions",
      customMeasures: "product/getCurrentCustomMeasures",
      compareList: "compare/getProductCompare",
      // getIsProductInCompareList: "compare/getIsProductInCompareList",
      // getCompareProductList: "compare/getCompareProductList",
    }),

    isInStock() {
      return this.product.stock_status == "IN_STOCK" ? true : false;
    },
    isProductInCompare() {
      return !!this.compareList.find(
        (product) => product.sku === this.product.sku
      );
    },
    getCurrentUrl() {
      if (!isServer) {
        return window.location.href;
      }
      return "";
    },
    currentChildSku() {
      return this.$store.getters["product/getCurrentChildSku"];
    },
    currentChildElement() {
      const childSKU = this.currentChildSku;
      if (childSKU != null) {
        return this.productConfig.variants.find(
          (element) => element.product.sku === childSKU
        );
      }
      return this.currentChildSku;
    },
    currentChildElementInfo() {
      console.log(
        "CURRENT CHILD ELEMENT",
        this.currentChildElement &&
          this.currentChildElement.attributes.reduce((prev, curr) => {
            prev[curr.code] = curr;
            return prev;
          }, {})
      );

      return (
        this.currentChildElement &&
        this.currentChildElement.attributes.reduce((prev, curr) => {
          prev[curr.code] = curr;
          return prev;
        }, {})
      );
    },
    btnDisable() {
      if (this.product.__typename == "ConfigurableProduct") {
        const retval = this.$store.getters["product/getCurrentChildSku"];
        if (retval != null) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    },
    buttonClass() {
      if (this.btnDisable == true) {
        return "cursor: not-allowed;";
      } else {
        return "";
      }
    },
    paymentPartners() {
      return this.$store.getters["sliders/getSliderByIdentifier"](
        "payment_providers"
      );
    },
    finalPrice() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range.minimum_price.final_price.value.toFixed(
              2
            );
          }
        }
        if (
          this.product.price_range.minimum_price.final_price.value !=
          this.product.price_range.maximum_price.final_price.value
        ) {
          return (
            this.product.price_range.minimum_price.final_price.value.toFixed(
              2
            ) +
            " - " +
            this.product.price_range.maximum_price.final_price.value
              .toFixed(2)
              .replace(".", ",")
          );
        }
        return this.product.price_range.minimum_price.final_price.value.toFixed(
          2
        );
      }
      if (
        this.product.price_range.minimum_price.final_price.value !=
        this.product.price_range.maximum_price.final_price.value
      ) {
        return (
          this.product.price_range.minimum_price.final_price.value
            .toFixed(2)
            .replace(".", ",") +
          " - " +
          this.product.price_range.maximum_price.final_price.value
            .toFixed(2)
            .replace(".", ",")
        );
      }
      return this.product.price_range.minimum_price.final_price.value.toFixed(
        2
      );
    },
    priceRange() {
      if (this.product.__typename == "ConfigurableProduct") {
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.price_range;
          }
        }
        return this.product.price_range;
      }
      return this.product.price_range;
    },
    availability() {
      if (this.product.__typename == "SimpleProduct") {
        return this.product.store_availability;
      }
      if (this.product.__typename == "ConfigurableProduct") {
        Logger.debug(
          "availability",
          "ProductParameters",
          this.product.__typename
        )();
        if (this.$store.getters["product/getCurrentChildSku"] != null) {
          const found = this.productConfig.variants.find((v) => {
            if (
              v.product.sku == this.$store.getters["product/getCurrentChildSku"]
            ) {
              return true;
            }
          });
          if (found != null) {
            return found.product.store_availability;
          }
        }
      }

      return [];
    },
    stockStatusClass() {
      return this.isInStock ? "in-stock" : "out-stock";
    },
  },

  watch: {
    currentChildSku(newValue) {
      if (newValue == null) {
        // this.priceSize = "";
      }
    },
  },

  data: () => ({
    quantity: 1,
    popup: false,
    addToCartModal: false,
    addToCompareModal: false,
    addToNotifyModal: false,
    priceSize: "",
    tableSize: [
      {
        name: "Billy / BHE",
        sizeS: "49.5 - 54 cm",
        sizeM: "55 - 58 cm",
        sizeL: "n/a",
      },
      {
        name: "Metro / BHE -55",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "56 - 61 cm",
      },
      {
        name: "Indra / BHE -56",
        sizeS: "n/a",
        sizeM: "55 - 58 cm",
        sizeL: "58 - 62 cm",
      },
    ],
    fields: [
      {
        key: "name",
        label: "",
      },
      {
        key: "sizeS",
        label: "S",
      },
      {
        key: "sizeM",
        label: "M",
      },
      {
        key: "sizeL",
        label: "L",
      },
    ],
  }),
  methods: {
    // ...mapActions({
    //   updateToggleInCompareList: "compare/updateToggleInCompareList",
    // }),
    clickOkCompare() {
      this.addToCompareModal = false;
      this.$router.push("/compare");
    },
    clickCancelCompare() {
      this.addToCompareModal = false;
      window.scrollTo(0, 0);
    },
    async onCompareClick() {
      if (this.isProductInCompare == false) {
        await this.$store.dispatch("compare/addCompareProduct", {
          sku: this.product.sku,
        });
        this.addToCompareModal = true;
      } else {
        await this.$store.dispatch("compare/removeCompareProduct", {
          sku: this.product.sku,
        });
      }
    },
    getColorCode(value) {
      if (this.product.__typename == "ConfigurableProduct") {
        const parameter = this.productConfig.configurable_options.find(
          (element) => element.attribute_code === "color"
        );
        if (parameter != null) {
          const object = parameter.values.find(
            (element) => element.value_index == value
          );
          if (object != null) {
            if (object.swatch_data) {
              return object.swatch_data.value;
            }
          }
        }
        // return "#ffffff"
        return null;
      }
      return null;
    },

    toggleIsInWishList() {
      this.$emit("changeIsInWishList");
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat("nl-NL", {
        style: "currency",
        currency: "EUR",
      });
      return formatter.format(value);
    },
    clickOk() {
      this.addToCartModal = false;
      this.$router.push("/checkout");
    },
    clickCancel() {
      this.addToCartModal = false;
      window.scrollTo(0, 0);
    },

    closeNotifyModal() {
      this.addToNotifyModal = false;
    },
    quantityUp() {
      this.quantity++;
    },
    quantityDown() {
      if (this.quantity > 1) this.quantity--;
    },
    processOption(index, value) {
      // console.log("INN PROCESS", { index, value });
      Logger.debug("processOption", "ProductParameters", {
        index: index,
        value: value,
      })();
      this.$store.commit("product/setOptionValue", {
        index: index,
        value: value,
      });
    },
    addToNotify() {
      this.addToNotifyModal = true;
    },
    async addToCart() {
      switch (this.product.__typename) {
        case "SimpleProduct": {
          const item =
            '{data: {sku: "' +
            this.product.sku +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("shopping_basket"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              if (!this.$gtm.uaDisabled) {
                window.dataLayer.push({
                  event: "eec.add",
                  ecommerce: {
                    add: {
                      products: [
                        {
                          name: this.product.name,
                          id: this.product.sku,
                          quantity: this.quantity,
                        },
                      ],
                    },
                  },
                });
              }
              if (this.$gtm.ga4Enabled) {
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems()],
                    },
                  },
                });
              }
            }
            this.addToCartModal = true;
            // this.$bvModal.show(`modal-${this.product.sku}`);
          }
          break;
        }
        case "ConfigurableProduct": {
          const item =
            '{ parent_sku: "' +
            this.product.sku +
            '" data: {sku: "' +
            this.$store.getters["product/getCurrentChildSku"] +
            '", quantity:' +
            this.quantity +
            "}}  ";
          let retval = await this.$store.dispatch("cart/addToCart", {
            type: this.product.__typename,
            item: item,
          });
          Logger.debug("retval", "addToCart", retval)();
          if (retval == false) {
            const msg = {
              type: "danger",
              title: this.$t("add_to_cart"),
              text: this.$t("not_add_product"),
            };
            this.$store.dispatch("messages/sendMessage", { message: msg });
          } else {
            if (this.$gtm.enabled()) {
              Logger.debug("Watch Product", "product.vue", "CALLED")();
              //GA event
              window.dataLayer.push({
                event: "eec.add",
                ecommerce: {
                  add: {
                    products: [
                      {
                        name: this.product.name,
                        id: this.product.sku,
                        quantity: this.quantity,
                      },
                    ],
                  },
                },
              });
              if (this.$gtm.ga4Enabled) {
                //GA4 event
                window.dataLayer.push({
                  event: "add_to_cart",
                  ecommerce: {
                    add: {
                      currency: "EUR",
                      value: this.finalPrice,
                      items: [this.getGTMItems()],
                    },
                  },
                });
              }
            }
            this.addToCartModal = true;
            // this.$bvModal.show("modal-1");
          }
          break;
        }

        default:
          break;
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.product-info {
  .product-parameter-submit-btn {
    background-color: $primaryColor;
    border-color: $primaryColor;

    &:hover {
      color: $primaryColor;
    }
  }

  .price-part {
    margin-bottom: 30px;

    label {
      min-width: 60px;
      width: auto;
      margin-right: 10px;
    }
  }

  .out-stock-box {
    margin-bottom: 15px;

    &.in-stock {
      color: $primaryColor;
    }

    h3 {
      font-size: 14px;
    }
  }
}
</style>


